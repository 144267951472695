'use client';
import { useEffect } from 'react';
import { permanentRedirect, redirect, usePathname } from 'next/navigation';

export type OngoingProgramGuardProps = {
  locale: string;
  noOngoingProgram: boolean;
  children: React.ReactNode;
};

export function OngoingProgramGuard({
  locale,
  noOngoingProgram,
  children,
}: OngoingProgramGuardProps): React.JSX.Element {
  const pathname = usePathname();

  useEffect(() => {
    if (noOngoingProgram) {
      if (!pathname.startsWith(`/${locale}/no-ongoing-program`)) {
        permanentRedirect(`/no-ongoing-program`);
      }
    } else {
      if (pathname.startsWith(`/${locale}/no-ongoing-program`)) {
        redirect(`/home`);
      }
    }
  }, [locale, pathname, noOngoingProgram]);

  return <>{children}</>;
}
