import { httpClient, RequestOptions } from '@core-systems/http';

export class LearningHttpRestApi {
  static async get<Response = void>(url: string, options?: RequestOptions): Promise<Response> {
    'use server';
    return httpClient.get<Response>(url, options);
  }

  static async post<Response = void>(url: string, body?: any, options?: RequestOptions): Promise<Response> {
    'use server';
    const payload = body || {};
    return httpClient.post<Response>(url, payload, options);
  }

  static async put<Response = void>(url: string, options?: RequestOptions): Promise<Response> {
    'use server';
    return httpClient.put<Response>(url, {}, options);
  }

  static async patch<Response = void>(url: string, body?: any, options?: RequestOptions): Promise<Response> {
    'use server';
    return httpClient.patch<Response>(url, body, options);
  }

  static async delete<Response = void>(url: string, options?: RequestOptions): Promise<Response> {
    'use server';
    return httpClient.delete<Response>(url, {}, options);
  }
}
