'use client';
import { FolderDot, LayoutDashboard, LucideHome, LucideIcon } from 'lucide-react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarGroup, SidebarGroupContent, SidebarMenu, SidebarMenuButton, SidebarMenuItem } from '@design-system';
import { I18nNamespace, invalidateCache, MENU_ITEMS_KEY, MenuTabs } from '@app-client-nx/learning-core';

type MenuItem = { nameKey: string; Icon: LucideIcon; path: string };

type MenuItemMap = [MENU_ITEMS_KEY, MenuItem];

const ALL_MENU_ITEMS_MAP: MenuItemMap[] = [
  [MENU_ITEMS_KEY.HOME, { nameKey: 'entries.home', Icon: LucideHome, path: 'home' }],
  [MENU_ITEMS_KEY.MODULES, { nameKey: 'entries.modules', Icon: LayoutDashboard, path: 'modules' }],
  [MENU_ITEMS_KEY.NO_ONGOING_PROGRAM, { nameKey: 'entries.home', Icon: FolderDot, path: 'no-ongoing-program' }],
];

export type MenuItemsProps = {
  menuTabs: MenuTabs;
};

export function MenuItems({ menuTabs }: MenuItemsProps): React.JSX.Element {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(I18nNamespace.MENU);
  const pathname = usePathname();

  const menuTabSet = new Set<MENU_ITEMS_KEY>(menuTabs);

  return (
    <SidebarGroup>
      <SidebarGroupContent>
        <SidebarMenu className="text-subtext">
          {ALL_MENU_ITEMS_MAP.map(([key, { Icon, nameKey, path }]) => {
            if (!menuTabSet.has(key)) {
              return null;
            }
            const href = `/${locale}/${path}`;
            return (
              <SidebarMenuItem key={`menu-item-${path}`}>
                <SidebarMenuButton asChild isActive={pathname.startsWith(href)}>
                  <Link href={href} onClick={() => invalidateCache(href)}>
                    <Icon />
                    <span>{t(nameKey)}</span>
                  </Link>
                </SidebarMenuButton>
              </SidebarMenuItem>
            );
          })}
        </SidebarMenu>
      </SidebarGroupContent>
    </SidebarGroup>
  );
}
