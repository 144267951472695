'use client';

import React, { PropsWithChildren } from 'react';

class LearnerContextConsumedOutsideProvider extends Error {}

type LearnerContextValue = {
  profile: LearnerProfile;
};

const LearnerContext = React.createContext<LearnerContextValue | null>(null);

export type LearnerProfile = {
  firstName: string;
  lastName: string;
};

export const useLearnerProfile = (): LearnerContextValue => {
  const { profile } = React.useContext(LearnerContext) || {};

  if (!profile) {
    throw new LearnerContextConsumedOutsideProvider();
  }

  return { profile };
};

export const LearnerProfileProvider = ({
  children,
  profile,
}: PropsWithChildren<{ profile: LearnerProfile }>): JSX.Element => {
  return <LearnerContext.Provider value={{ profile }}>{children}</LearnerContext.Provider>;
};
