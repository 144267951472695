'use client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircleHelp, MessageCircleQuestion } from 'lucide-react';
import { I18nNamespace, SidebarButtonGroup } from '@app-client-nx/learning-core';
import { SidebarGroup, SidebarGroupContent } from '@design-system';

const faqUrl = 'https://42playground.duckdns.org/faq/index.php?page=7ea82955-0d4d-44a7-999a-0eb01d4637b0';

export function MenuFooter(): React.JSX.Element {
  const { t } = useTranslation(I18nNamespace.MENU);

  return (
    <SidebarGroup>
      <SidebarGroupContent>
        <SidebarButtonGroup
          actionIcon={MessageCircleQuestion}
          itemGroups={[
            [
              {
                Icon: CircleHelp,
                href: faqUrl,
                translationKey: t('entries.help.faq'),
                key: 'faq-button',
                newTab: true,
              },
            ],
          ]}
        />
      </SidebarGroupContent>
    </SidebarGroup>
  );
}
