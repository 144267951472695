import { failableAction, FailableServerAction } from '@core-systems/http';
import { ReviewToPerform } from '../components';
import { AllReviewsResponse, Learner, Module, ModuleDetails, ReviewForm } from '../domain';
import { Homepage as HomepageResponse } from '../domain/homepage/homepage';
import { MenuTabsResponse } from '../domain';
import { Project, ProjectWithLastAttempt } from '../domain';
import { RushesApi } from './apis/rushes-api';
import { LearningHttpRestApi } from './learning-http-rest-api';

export type AllModulesResponse = { results: Module[] };
export type AllProjectsResponse = { results: Project[] };
export type ReviewsToPerformResponse = { reviews: ReviewToPerform[] };
export type FeatureFlagStatus = { isEnabled: boolean };

export class LearningHttp {
  public static readonly rushes = RushesApi;

  public static async listAllModules(): Promise<AllModulesResponse> {
    'use server';

    return LearningHttpRestApi.get<AllModulesResponse>('/v1/learning/modules');
  }

  public static async fetchModuleDetails(id: string): Promise<FailableServerAction<ModuleDetails>> {
    'use server';

    return await failableAction(this.fetchModuleDetails, () =>
      LearningHttpRestApi.get<ModuleDetails>(`/v1/learning/modules/${id}`),
    );
  }

  public static async listAllProjects(): Promise<AllProjectsResponse> {
    'use server';

    return LearningHttpRestApi.get<AllProjectsResponse>('/v1/learning/projects');
  }

  public static async fetchProjectDetails(id: string): Promise<FailableServerAction<ProjectWithLastAttempt>> {
    'use server';

    return await failableAction(this.fetchProjectDetails, () =>
      LearningHttpRestApi.get<ProjectWithLastAttempt>(`/v1/learning/projects/${id}`),
    );
  }

  public static async fetchFeatureFlagStatus(name: string): Promise<FailableServerAction<FeatureFlagStatus>> {
    'use server';

    return await failableAction(this.fetchFeatureFlagStatus, () =>
      LearningHttpRestApi.get<FeatureFlagStatus>(`/v1/feature-flags/${name}/status`),
    );
  }

  public static async fetchMyProfile(): Promise<FailableServerAction<Learner>> {
    'use server';

    return await failableAction(this.fetchMyProfile, () =>
      LearningHttpRestApi.get<Learner>(`/v1/learning/learners/me`),
    );
  }

  public static async fetchReviewForm(reviewId: string): Promise<FailableServerAction<ReviewForm>> {
    'use server';

    return await failableAction(this.fetchReviewForm, () =>
      LearningHttpRestApi.get<ReviewForm>(`/v1/learning/reviews/${reviewId}/form`),
    );
  }

  public static async fetchAllReviews(): Promise<FailableServerAction<AllReviewsResponse>> {
    'use server';

    return await failableAction(this.fetchProjectDetails, () =>
      LearningHttpRestApi.get<AllReviewsResponse>('/v1/admin/reviews'),
    );
  }

  public static async fetchMenu(): Promise<FailableServerAction<MenuTabsResponse>> {
    'use server';

    return await failableAction(this.fetchMenu, () => LearningHttpRestApi.get<MenuTabsResponse>(`/v1/learning/menu`));
  }

  public static async fetchHomepage(): Promise<HomepageResponse> {
    'use server';

    return await LearningHttpRestApi.get<HomepageResponse>('/v1/learning/homepage');
  }
}
