import { failableAction, FailableServerAction } from '@core-systems/http';
import { AdminRushDetails, AdminRushes, RushDetails } from '../../domain';
import { LearningHttpRestApi } from '../learning-http-rest-api';

export class RushesApi {
  public static async fetchRushDetails(id: string): Promise<FailableServerAction<RushDetails>> {
    'use server';

    return await failableAction(this.fetchRushDetails, () =>
      LearningHttpRestApi.get<RushDetails>(`/v1/learning/rushes/${id}`),
    );
  }

  public static async fetchAdminRushes(programSessionId: string): Promise<FailableServerAction<AdminRushes>> {
    'use server';

    return await failableAction(this.fetchRushDetails, () =>
      LearningHttpRestApi.get<AdminRushes>(`/v1/admin/learning/program-sessions/${programSessionId}/rushes`),
    );
  }

  public static async fetchAdminRush(
    programSessionId: string,
    rushId: string,
  ): Promise<FailableServerAction<AdminRushDetails>> {
    'use server';

    return await failableAction(this.fetchAdminRush, () =>
      LearningHttpRestApi.get<AdminRushDetails>(
        `/v1/admin/learning/program-sessions/${programSessionId}/rushes/${rushId}`,
      ),
    );
  }
}
